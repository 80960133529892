/*
  Authors : initappz (Rahul Jograna)
  Website : https://initappz.com/
  App Name : ionic 5 foodies app
  Created : 28-Feb-2021
  This App Template Source code is licensed as per the
  terms found in the Website https://initappz.com/license
  Copyright and Good Faith Purchasers © 2020-present initappz.
*/
import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMS = [
  {
    label: 'Main',
    main: [
      {
        state: 'dashboard',
        name: 'Dashboard',
        type: 'link',
        icon: 'ti-home'
      },
      {
        state: 'city',
        name: 'Available Cities',
        type: 'link',
        icon: 'ti-location-pin'
      },
      {
        state: 'stores',
        name: 'Chefs',
        type: 'link',
        icon: 'ti-user'
      },
      {
        state: 'users',
        name: 'Users',
        type: 'link',
        icon: 'ti-user'
      },
      /*{
        state: 'drivers',
        name: 'Drivers',
        type: 'link',
        icon: 'ti-truck'
      },*/
      {
        state: 'orders',
        name: 'View Online Orders',
        type: 'link',
        icon: 'ti-shopping-cart'
      },
      {
        state: 'api-orders',
        name: 'API Orders',
        type: 'link',
        icon: 'ti-shopping-cart-full'
      },
      {
        state: 'custom-order-add',
        name: 'Add Custom Order',
        type: 'link',
        icon: 'ti-package',
      },
      {
        state: 'custom-orders',
        name: 'View Custom Orders',
        type: 'link',
        icon: 'ti-shopping-cart',
      },
      {
        state: 'api-custom-orders',
        name: 'API Custom Orders',
        type: 'link',
        icon: 'ti-shopping-cart-full'
      },
      {
        state: 'category',
        name: 'Categories',
        type: 'link',
        icon: 'ti-layout-grid2'
      },
      {
        state: 'special-category',
        name: 'Festival/Special Days',
        type: 'link',
        icon: 'ti-layout-grid2'
      },
      /*{
        state: 'products',
        name: 'Products',
        type: 'link',
        icon: 'ti-envelope'
      },*/
      {
        state: 'user-registration',
        name: 'User Sign-up',
        type: 'link',
        icon: 'ti-plus',
      },
      {
        state: 'chef-registration',
        name: 'Chef Sign-up',
        type: 'link',
        icon: 'ti-plus',
      },
      {
        state: 'chef-payments',
        name: 'Chef Payment History',
        type: 'link',
        icon: 'ti-credit-card',
      }
      /*,
      {
        state: 'payment',
        name: 'Payments',
        type: 'link',
        icon: 'ti-money'
      }*/
    ],
  },
  {
    label: 'Manage',
    main: [
      /*{
        state: 'languages',
        name: 'Languages',
        type: 'link',
        icon: 'ti-world'
      },*/
      {
        state: 'banners',
        name: 'Banners',
        type: 'link',
        icon: 'ti-layout-list-large-image',
      },
      {
        state: 'blogs',
        name: 'Press',
        type: 'link',
        icon: 'ti-book',
      },
      {
        state: 'offers',
        name: 'Coupons',
        type: 'link',
        icon: 'ti-medall'
      },
      /*{
        state: 'notifications',
        name: 'Notification',
        type: 'link',
        icon: 'ti-bell'
      },*/
      /*{
        state: 'contacts',
        name: 'Support',
        type: 'link',
        icon: 'ti-comments-smiley'
      },*/
      /*{
        state: 'emails',
        name: 'Contacts',
        type: 'link',
        icon: 'ti-email'
      },*/
      {
        state: 'commission-stats',
        name: 'Commission',
        type: 'link',
        icon: 'ti-stats-up'
      },
      {
        state: 'chefs',
        name: 'Chef Registeration',
        type: 'link',
        icon: 'ti-stats-up'
      },
      {
        state: 'stats',
        name: 'Online Order Stats',
        type: 'link',
        icon: 'ti-stats-up'
      },
      {
        state: 'custom-order-stats',
        name: 'Custom Order Stats',
        type: 'link',
        icon: 'ti-stats-up'
      },
      {
        state: 'invoice',
        name: 'Commission invoice',
        type: 'link',
        icon: 'ti-stats-up'
      },

      /*{
        state: 'driver-stats',
        name: 'Drivers Stats',
        type: 'link',
        icon: 'ti-stats-up'
      },*/


      /*{
        state: 'manage-app',
        name: 'Manage App',
        type: 'link',
        icon: 'ti-lock'
      },
      {
        state: 'send-mail',
        name: 'Send Emails',
        type: 'link',
        icon: 'ti-email'
      },*/
      {
        state: 'change-password',
        name: 'Vendor Password',
        type: 'link',
        icon: 'ti-user'
      },
      {
        state: 'app-settings',
        name: 'App Settings',
        type: 'link',
        icon: 'ti-desktop'
      },
      {
        state: 'general',
        name: 'General',
        type: 'link',
        icon: 'ti-settings'
      },
      {
        state: 'app-pages',
        name: 'App Pages',
        type: 'link',
        icon: 'ti-blackboard'
      },
      {
        state: 'manage-popup',
        name: 'Manage Popup',
        type: 'link',
        icon: 'ti-quote-right'
      },
      {
        state: 'administrantor',
        name: 'Administrator',
        type: 'link',
        icon: 'ti-id-badge'
      }

    ]
  },

];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }

  /*add(menu: Menu) {
    MENUITEMS.push(menu);
  }*/
}
